<template>
  <div :class="{'order-create__section': !isAdvanced}">

    <template>

      <div class="mb-2 color-orange-validation" v-if="ex.validation.products">
        {{ $t(`${ex.validationTranslate.products}.localization_value.value`) }}
      </div>
      <template v-for="(item, index) in ex.express.products">
        <div class="order-create__title-horizontal"
             :key="index + '1'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
          <TitleHorizontal
              :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
              :rightBtn="true"
              :rightBtnType="'delete'"
              :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
              @rightBtnClick="$emit('removeProduct', index)"
          />
        </div>

        <div :key="index">
          <ProductItemCreation
              :key="loadedProformOptions + proformChanged"
              :product="item"
              :serverError="serverError"
              :canChangeHSCode="true"
              :visibleHSCode="ex.express.visibleHSCode"
              :altVersion="true"
              :nameForLabel="ex.express.type === FEDEX_DELIVERY_SERVICE.id && isAdmin"
              @changeCategory="({val, nameVal}) => $emit('changeCategory', {val, nameVal, item: ex.express.products[index]})"
              @changeProformSelectItem="changeProformSelectItem(item, ex.express.delivery.delivery.country); changeProformSelectExpress(item)"
              @changeSavedProform="val => changeSavedProformExpress(val)"
          />
        </div>
        <!--v-bind:class="{'disabled-product':-->
        <!--$store.getters.getIsAdminRights === 'admin' && Object.keys(item.product.user).length === 0}"-->
      </template>


      <div class="order-create__product-btn add-product-btn-wrap"
           v-bind:class="{'mb-0' : ex.express.type === DHL_DELIVERY_SERVICE.id,
					 	'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
      >
        <div class="admin-edit" @click="editTranslate([
							'common_AddProduct',
						])"></div>
        <span class="order-create__product-btn-i add-product-btn site-link"
              @click="$emit('addProduct')"
        >
						+ {{ $t('common_AddProduct.localization_value.value') }}
					</span>
      </div>

      <template v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						'express_DocumentsINeed',
					])"></div>
          {{ $t('express_DocumentsINeed.localization_value.value') }}
        </div>

        <div class="custom-row">
          <div class="custom-col d-flex align-items-center mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
							'express_ToxicSubstance',
							'express_NeededForSuch',
						])"></div>
            <DefaultCheckbox
                v-model="ex.express.toxicSubstanceControlActCert"
                :label="''"
                @click="ex.express.toxicSubstanceControlActCert = !ex.express.toxicSubstanceControlActCert"
            >
             <template slot="content">
               <v-popover
                   class="site-tooltip"
                   offset="5"
                   placement="top"
                   trigger="hover"
               >
                 {{$t('express_ToxicSubstance.localization_value.value')}}
                 <TooltipBtn/>
                 <template slot="popover">
                   <p><b>{{ $t('express_ToxicSubstance.localization_value.value') }}</b></p>
                   <p>{{ $t('express_NeededForSuch.localization_value.value') }}</p>
                   <a v-close-popover class="tooltip__close"></a>
                 </template>
               </v-popover>
             </template>
            </DefaultCheckbox>

          </div>
          <div class="custom-col d-flex align-items-center mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
							'express_DropBallTest',
							'express_DropBallTestNeededFor',
						])"></div>
            <DefaultCheckbox
                v-model="ex.express.dropBallTestCert"
                :label="$t('express_DropBallTest.localization_value.value')"
                @click="ex.express.dropBallTestCert = !ex.express.dropBallTestCert"
            />
            <v-popover
                class="site-tooltip"
                offset="5"
                placement="top"
                trigger="hover"
            >
              <TooltipBtn/>
              <template slot="popover">
                <p><b>{{ $t('express_DropBallTest.localization_value.value') }}</b></p>
                <p>{{ $t('express_DropBallTestNeededFor.localization_value.value') }}</p>
                <a v-close-popover class="tooltip__close"></a>
              </template>
            </v-popover>
          </div>
        </div>
      </template>

      <div class="order-create__total"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
						'common_TotalShippingCost',
						'express_orderPriceValidate',
					])"></div>
        {{ $t('common_TotalShippingCost.localization_value.value') }}

        <span>
					${{ calculatePrice }}
				</span>
        <div
            :key="ex.validation.orderPrice"
            v-if="ex.validation.orderPrice"
            v-bind:class="{'ui-no-valid': ex.validation.orderPrice}">
          <div class="error-field error-field-right pr-2">
            {{ $t(`${ex.validationTranslate.orderPrice}.localization_value.value`) }}
            (${{ parseFloat(ex.express.orderPrice).toFixed(2) }})
          </div>

        </div>
      </div>

    </template>

    <PromoCode
        v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
        :ex="ex"
    />

  </div>
</template>

<script>
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
} from "../../../../../../../staticData/staticVariables";
import {expressMixins} from "../../../../../../../mixins/expressMixins/expressMixin";
import {proformMixins} from "../../../../../../../mixins/proformMixins/proformMixins";
import PromoCode from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/PromoCode/PromoCode";


export default {
  name: "ProductsDetails",
  components: {
    PromoCode,
    TooltipBtn,
    DefaultCheckbox,
    TitleHorizontal,
    ProductItemCreation,
  },

  props: {
    ex: Object,
    loadedProformOptions: {
      type: Number,
      default: 0,
    },
    proformChanged: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isAdvanced: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [mixinDetictingMobile, expressMixins, proformMixins],

  //cuz admin isn't forced to select country first
  mounted() {
    if (!this.isEdit && this.isAdmin && this.ex.express.type === DHL_DELIVERY_SERVICE.id) {
      this.ex.setVisibleHSCode(true)
    }
  },

  data() {
    return {
      options: [{}],


      valueQuantity: 1,
      valueSum: 0,
      valueSumDisable: 100,

      DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
      FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
      NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,


      isHovering: false,
      tooltipAddProductActive: true,
      serverError: false,

      product: {
        sku: '',
        skuOption: [{}],
        categoryOption: [{}],
        categorySelected: '',
        nameUa: '',
        nameEn: '',
        forUa: '',
        forEn: '',
        materialUa: '',
        materialEn: '',
        subMaterialUa: '',
        subMaterialEn: '',
        valueQuantity: '',
        valueSum: '',
      }
    }
  },

  // computed: {
  // 	calculatePrice() {
  // 		let price = 0
  // 		this.ex.express.products.map(item => {
  // 			console.log(parseFloat(item.product.price * item.product.itemsQuantity));
  // 			price += parseFloat(item.product.price * item.product.itemsQuantity)
  // 		})
  //
  // 		return this.$options.filters.number(price, '0.00')
  // 	},
  // },


  methods: {
    changeValueQuantity(data) {
      this.valueQuantity = data
    },

    changeValueSum(data) {
      this.valueSum = data
    },

    changeProductNameEn() {
      console.log('changeProductNameEn')
    },

    changeProductNameUa() {
      console.log('changeProductNameUa')
    },


  }
}
</script>

<style scoped>

.error-field-right {
  left: auto;
  right: 0;
  font-size: 14px;
}

.order-create__total .ui-no-valid {
  position: relative;
}
</style>
