var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'order-create__section': !_vm.isAdvanced}},[[(_vm.ex.validation.products)?_c('div',{staticClass:"mb-2 color-orange-validation"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.ex.validationTranslate.products}.localization_value.value`))+" ")]):_vm._e(),_vm._l((_vm.ex.express.products),function(item,index){return [_c('div',{key:index + '1',staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1),"rightBtn":true,"rightBtnType":'delete',"rightBtnText":_vm.$t('common_DeleteProduct.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.$emit('removeProduct', index)}}})],1),_c('div',{key:index},[_c('ProductItemCreation',{key:_vm.loadedProformOptions + _vm.proformChanged,attrs:{"product":item,"serverError":_vm.serverError,"canChangeHSCode":true,"visibleHSCode":_vm.ex.express.visibleHSCode,"altVersion":true,"nameForLabel":_vm.ex.express.type === _vm.FEDEX_DELIVERY_SERVICE.id && _vm.isAdmin},on:{"changeCategory":({val, nameVal}) => _vm.$emit('changeCategory', {val, nameVal, item: _vm.ex.express.products[index]}),"changeProformSelectItem":function($event){_vm.changeProformSelectItem(item, _vm.ex.express.delivery.delivery.country); _vm.changeProformSelectExpress(item)},"changeSavedProform":val => _vm.changeSavedProformExpress(val)}})],1)]}),_c('div',{staticClass:"order-create__product-btn add-product-btn-wrap",class:{'mb-0' : _vm.ex.express.type === _vm.DHL_DELIVERY_SERVICE.id,
					 	'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_AddProduct',
						])}}}),_c('span',{staticClass:"order-create__product-btn-i add-product-btn site-link",on:{"click":function($event){return _vm.$emit('addProduct')}}},[_vm._v(" + "+_vm._s(_vm.$t('common_AddProduct.localization_value.value'))+" ")])]),(_vm.ex.express.type === _vm.FEDEX_DELIVERY_SERVICE.id)?[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						'express_DocumentsINeed',
					])}}}),_vm._v(" "+_vm._s(_vm.$t('express_DocumentsINeed.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col d-flex align-items-center mb-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_ToxicSubstance',
							'express_NeededForSuch',
						])}}}),_c('DefaultCheckbox',{attrs:{"label":''},on:{"click":function($event){_vm.ex.express.toxicSubstanceControlActCert = !_vm.ex.express.toxicSubstanceControlActCert}},model:{value:(_vm.ex.express.toxicSubstanceControlActCert),callback:function ($$v) {_vm.$set(_vm.ex.express, "toxicSubstanceControlActCert", $$v)},expression:"ex.express.toxicSubstanceControlActCert"}},[_c('template',{slot:"content"},[_c('v-popover',{staticClass:"site-tooltip",attrs:{"offset":"5","placement":"top","trigger":"hover"}},[_vm._v(" "+_vm._s(_vm.$t('express_ToxicSubstance.localization_value.value'))+" "),_c('TooltipBtn'),_c('template',{slot:"popover"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('express_ToxicSubstance.localization_value.value')))])]),_c('p',[_vm._v(_vm._s(_vm.$t('express_NeededForSuch.localization_value.value')))]),_c('a',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"tooltip__close"})])],2)],1)],2)],1),_c('div',{staticClass:"custom-col d-flex align-items-center mb-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_DropBallTest',
							'express_DropBallTestNeededFor',
						])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_DropBallTest.localization_value.value')},on:{"click":function($event){_vm.ex.express.dropBallTestCert = !_vm.ex.express.dropBallTestCert}},model:{value:(_vm.ex.express.dropBallTestCert),callback:function ($$v) {_vm.$set(_vm.ex.express, "dropBallTestCert", $$v)},expression:"ex.express.dropBallTestCert"}}),_c('v-popover',{staticClass:"site-tooltip",attrs:{"offset":"5","placement":"top","trigger":"hover"}},[_c('TooltipBtn'),_c('template',{slot:"popover"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('express_DropBallTest.localization_value.value')))])]),_c('p',[_vm._v(_vm._s(_vm.$t('express_DropBallTestNeededFor.localization_value.value')))]),_c('a',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"tooltip__close"})])],2)],1)])]:_vm._e(),_c('div',{staticClass:"order-create__total",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						'common_TotalShippingCost',
						'express_orderPriceValidate',
					])}}}),_vm._v(" "+_vm._s(_vm.$t('common_TotalShippingCost.localization_value.value'))+" "),_c('span',[_vm._v(" $"+_vm._s(_vm.calculatePrice)+" ")]),(_vm.ex.validation.orderPrice)?_c('div',{key:_vm.ex.validation.orderPrice,class:{'ui-no-valid': _vm.ex.validation.orderPrice}},[_c('div',{staticClass:"error-field error-field-right pr-2"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.ex.validationTranslate.orderPrice}.localization_value.value`))+" ($"+_vm._s(parseFloat(_vm.ex.express.orderPrice).toFixed(2))+") ")])]):_vm._e()])],(_vm.ex.express.type === _vm.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id)?_c('PromoCode',{attrs:{"ex":_vm.ex}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }